














































import Vue from "vue";
import PricingTable from "@/components/PricingTable.vue";
import PreFooter from "@/components/PreFooter.vue";
import SignupButton from "@/components/SignupButton.vue";
import FAQ from "@/components/FAQ.vue";
import Testimonial from "@/components/Testimonial.vue";
import faqs from "@/assets/data/faqs.json";
import testimonials from "@/assets/data/testimonials.json";
import api from "@/api/api";
import { IPlan } from "@/types";

export default Vue.extend({
  components: {
    PricingTable,
    PreFooter,
    FAQ,
    Testimonial,
    SignupButton,
  },
  data() {
    return {
      plans: [] as IPlan[],
      faqs,
      testimonials,
    };
  },
  computed: {
  },
  metaInfo(): { title: string } {
    return this.$route.meta;
  },
  async mounted() {
    this.plans = (await api.getMembersipPlans());
  },
});
