







import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  methods: {

  },
});
