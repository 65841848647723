


















































































































































































































import Vue from "vue";
import { CheckCircleIcon } from "vue-feather-icons";
import "v-title/lib/element-ui";
import SignupButton from "@/components/SignupButton.vue";
import ToggleButton from "@/components/ToggleButton.vue";
import planItems from "@/assets/data/plan-items.json";

export default Vue.extend({
  components: {
    CheckCircleIcon,
    SignupButton,
    ToggleButton,
  },
  props: {
    plans: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      planItems,
      annually: true,
    };
  },
  methods: {
    switchPeriod(val: any) {
      this.annually = val;
    },
  },
  filters: {
    formatNumber(val: number) {
      if (!Number.isFinite(val)) {
        return "Unlimited";
      }
      return val;
    },
    formatNumUsers(val: number) {
      if (val === 1) {
        return "Just you";
      }
      if (!Number.isFinite(val)) {
        return "Unlimited members";
      }
      return `Up to ${val} team members`;
    },
  },
});
